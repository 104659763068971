import React from "react";

// Layout
import SecondaryLanding from "../../../layouts/secondary-landing";

// Components
import BreadcrumbAuto from "../../../components/breadcrumb/breadcrumb-auto";
import SEO from "../../../components/seo/seo";
import ArticleCategoriesSection from "../../../components/blog-categories/article-categories-section";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";

import { buildingRemodelingCards } from "../../../data/blog-cards-data";

const category = {
  id: "Article-categories_3",
  Name: "Building and Remodeling",
  Slug: "building-remodeling",
  articles: buildingRemodelingCards,
  SEO: {
    MetaDescription:
      "Looking for building and home improvement articles? Read the latest tips from WaFd Bank if you're thinking about building or remodel your home.",
    MetaTitle: "Building & Home Improvement Articles"
  }
};

const BuildingRemodelingIndex = ({ location }) => {
  const pageLocation = { location };
  const categoryName = category.Name;

  const metaTitle = category.SEO && category.SEO.MetaTitle;
  const metaDescription = category.SEO && category.SEO.MetaDescription;

  const SEOData = {
    title: metaTitle,
    meta: [
      {
        name: "title",
        property: "og:title",
        content: metaTitle
      },
      {
        name: "description",
        property: "og:description",
        content: metaDescription
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/blog/" + category.Slug
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      }
    ]
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <BreadcrumbAuto {...pageLocation} />
      <SEO {...SEOData} />

      <ArticleCategoriesSection categoryName={categoryName} articles={category.articles} />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default BuildingRemodelingIndex;
